$(function() {
    //
    // Global
    //
    let controller = new ScrollMagic.Controller();
    const $root = $('html, body');
    let _windowWidth = window.outerWidth;
    let _resized;

    let iframeDesktop = document.getElementById("rain-tas-hero__video-desktop");
    let iframeMobile = document.getElementById("rain-tas-hero__video-mobile");

    let playerDesktop = new Vimeo.Player(iframeDesktop);
    let playerMobile = new Vimeo.Player(iframeMobile);

    //
    // Header
    //
    new ScrollMagic.Scene({
        triggerElement: "body",
        triggerHook: 0,
        offset: 1
    })
        // .addIndicators()
        .addTo(controller)
        .on("enter", function () {
            $(".rain-tas-header").addClass("rain-tas-header-sticky");
        })
        .on("leave", function () {
            $(".rain-tas-header").removeClass("rain-tas-header-sticky");
        });

    // Smooth scrolling for anchor link with #
    function smoothScroll() {
        $(document).on("click", 'a[href^="#"]', function () {
            let _scrollID = $.attr(this, 'data-scroll');

            if ($(_scrollID).length) {
                $root.animate({
                    scrollTop: $(_scrollID).offset().top - 63
                }, 300);
            }
            return false;
        });
    }

    smoothScroll();

    function landingPageNavTriggers() {
        let navTriggerArray = [];

        $(".rain-nav-trigger").each(function (i, obj) {
            navTriggerArray[i] = new ScrollMagic.Scene({
                triggerElement: obj,
                triggerHook: 0,
                offset: -64
            })
                // .addIndicators()
                .addTo(controller);

            navTriggerArray[i]
                .on("enter", function (event) {
                    $(".rain-tas-header .rain-link-inherit").removeClass("active");
                    $(".js-nav-" + i).addClass("active");
                })
                .on("leave", function (event) {
                    $(".rain-tas-header .rain-link-inherit").removeClass("active");
                    $(".js-nav-" + i).prev().addClass("active");
                });
        });
    }

    landingPageNavTriggers();

    function startVideo() {
		$("#js-play-video").click(function(e) {
			e.preventDefault();
			$(".rain-tas-hero-overlay, #js-play-video").fadeOut(function() {
				if (window.outerWidth <= 767) {
					playerMobile.play();
				} else {
					playerDesktop.play();
				}
			});
		});



		playerMobile.on("play", function() {
			pushGAevent('TakeAStandLP','Video_Play','VideoID_780530765');
		});
		playerDesktop.on("play", function() {
			pushGAevent('TakeAStandLP','Video_Play','VideoID_780530765');
		});
		playerMobile.on('ended', function(data) {
			pushGAevent('TakeAStandLP','Video_Complete','VideoID_780530765');
		});
		playerDesktop.on('ended', function(data) {
			pushGAevent('TakeAStandLP','Video_Complete','VideoID_780530765');
		});
    }

    startVideo();

    function resizeWidthOnly() {
        let currentWindowWidth = window.outerWidth;
        if (_windowWidth !== currentWindowWidth) {
            _windowWidth = currentWindowWidth;
            return _windowWidth;
        } else {
            return false;
        }
    }

    function resizedw() {
        if (resizeWidthOnly() !== false) {
            pauseVideoOnResize();
        }
    }

    window.onresize = function () {
        clearTimeout(_resized);
        _resized = setTimeout(resizedw, 100);
    }

    function pauseVideoOnResize() {
        playerDesktop.pause();
        playerMobile.pause();
    }
});

// ===================================================================
// Global Function to push GA events to GTM dataLayer
// ===================================================================
function pushGAevent(category,action,label){
	if (typeof window.dataLayer !== 'undefined'){
		window.dataLayer.push({
			'event': 'Google Analytics - Event',
			'GAEvent_category': 'TakeAStandLP',
			'GAEvent_action': action,
			'GAEvent_label': label
		});
	}
}